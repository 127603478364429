import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../styles/theme'

const getDescription = (status: YearEndModuleStatusOptions) => {
  const baseDescription =
    'If you’ve paid one or more contractors $600 or more throughout 2024, you’ll need to complete this survey so we can file 1099-NEC’s on your behalf.'
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <>
          Heard is no longer be able to file a 1099-NEC on your behalf. We
          recommend using{' '}
          <Link
            href="https://www.track1099.com/"
            newPage
            size="small"
            style={{
              textDecoration: 'underline',
              color: Colors.charcoal,
              textDecorationColor: Colors.charcoal,
            }}
          >
            www.track1099.com
          </Link>{' '}
          to do so.
        </>
      )
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return 'We’re filing your 1099-NEC(s).'
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Your 1099-NEC(s) have been filed.'
    }
    default:
      return null
  }
}

const File1099NECCard = ({
  status,
  dueDate,
  taxYear,
}: YearEndModuleStatusDynamicDataParams) => {
  const navigate = useNavigate()
  const description = getDescription(status)
  const onActionClick = () => {
    navigate('/taxes/annual/file_1099_nec/verify_business')
  }
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.file1099Nec}
      status={status}
      dueDate={dueDate}
      taxYear={taxYear}
      header="File 1099-NEC"
      imageUrl="https://heard-images.s3.amazonaws.com/assets/form-fill.svg"
      description={<Text as="bodySm">{description}</Text>}
      timer={{
        unit: 'minutes',
        value: 25,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            Not filing 1099-NEC’s could incur penalties from the IRS.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/articles/4421104098583-What-do-I-need-to-do-in-Heard-prior-to-filling-out-my-Tax-Checklist"
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        onActionClick,
      }}
    />
  )
}

export default File1099NECCard
