import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useNavigate } from 'react-router-dom'

const getDescription = (status: YearEndModuleStatusOptions) => {
  const baseDescription =
    'Fill out a short survey that will give us what we need to file an extension. We’ll then connect you to a tax preparer to complete the process.'
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.earlyStart:
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          availableAfterText={
            'Available after completing Verify tax entity status'
          }
        >
          {baseDescription}
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <>
          <Text as="bodySm">
            We are no longer able to file your extension request.
          </Text>
          <br />
          <Text as="bodySm">
            You are now{' '}
            <b>at risk of incurring failure to file penalties and interest</b>.
            Please file your tax return(s) as soon as you can.
          </Text>
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      return 'We’ve received your extension request survey. We’ll connect you to a tax preparer soon.'
    }
    default:
      return null
  }
}

const SubmitExtensionRequestCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
}: YearEndModuleStatusDynamicDataParams) => {
  const navigate = useNavigate()
  const description = getDescription(status)
  const onActionClick = () => {
    navigate('/taxes/annual/extension_request/combined/')
  }
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.submitExtensionRequest}
      taxYear={taxYear}
      status={status}
      dueDate={dueDate}
      header="Submit extension request"
      imageUrl="https://heard-images.s3.amazonaws.com/assets/tax-extension.svg"
      description={<Text as="bodySm">{description}</Text>}
      substepIdentifier={substepIdentifier}
      timer={{
        unit: 'minutes',
        value: 25,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            After that you’re at risk of incurring{' '}
            <b>failure-to-file penalties and interest</b>. We can’t file an
            extension for you after the deadline.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/sections/4416196135959-Annual-Taxes"
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        onActionClick,
      }}
    />
  )
}

export default SubmitExtensionRequestCard
