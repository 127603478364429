import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'
import { useNavigate } from 'react-router-dom'
import {
  DATE_FORMATS_LUXON,
  formatISOFromUTC,
} from '../../../utils/dateHelpers'

const getDescription = (
  status: YearEndModuleStatusOptions,
  taxYear: string,
  completedAt?: string | null
) => {
  const baseDescription = `Prepare your ${taxYear} books for annual taxes. We’ll ask you to review your accounts and transactions, as well as upload any documents we need to finalize your books for the year.`
  switch (status) {
    case YearEndModuleStatusOptions.upNext:
    case YearEndModuleStatusOptions.inProgress: {
      return baseDescription
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          availableAfterText={'Available after verifying your tax entity.'}
        >
          {baseDescription}
        </UpcomingStatusTextWrapper>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <>
          <Text as="bodySm">
            You will now <b>need to file an extension for taxes</b>. You still
            need to complete this task to prepare your books.
          </Text>
          <br />
          <Text as="bodySm">
            If you need help, please reach out to your accountant or tax
            professional.
          </Text>
        </>
      )
    }
    case YearEndModuleStatusOptions.complete: {
      const completedText = completedAt ? (
        <span>
          Completed{' '}
          <b>
            {formatISOFromUTC(completedAt, DATE_FORMATS_LUXON.DISPLAY_LONG)}
          </b>
          .{' '}
        </span>
      ) : (
        ''
      )
      return (
        <span>
          {completedText}Next, we’ll review your submission and let you know if
          we need anything else. You’ll be able to review your books again
          before closing them.
        </span>
      )
    }
    default:
      return null
  }
}

const EOYBookkeepingCard = ({
  status,
  dueDate,
  taxYear,
  substepIdentifier,
  completedAt,
}: YearEndModuleStatusDynamicDataParams) => {
  const navigate = useNavigate()
  const description = getDescription(status, taxYear, completedAt)
  const onActionClick = () => {
    navigate('/bookkeeping/end-of-year-review')
  }
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.eoyBookkeeping}
      taxYear={taxYear}
      status={status}
      dueDate={dueDate}
      header="Complete Year-End Bookkeeping"
      imageUrl="https://heard-images.s3.amazonaws.com/assets/books.svg"
      description={<Text as="bodySm">{description}</Text>}
      substepIdentifier={substepIdentifier}
      timer={{
        unit: 'minutes',
        value: 25,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: (
          <Text as="bodySm">
            After that, you’ll need to file an extension for your taxes.{' '}
            <Link
              href="https://support.joinheard.com/hc/en-us/sections/4416196135959-Annual-Taxes"
              newPage
              size="small"
              style={{
                textDecoration: 'underline',
                color: Colors.charcoal,
                textDecorationColor: Colors.charcoal,
              }}
            >
              More info
            </Link>
          </Text>
        ),
      }}
      button={{
        onActionClick,
      }}
    />
  )
}

export default EOYBookkeepingCard
