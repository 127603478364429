import { CSSProperties, ElementType } from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

// Panels
import ResetPassword from '../Auth/ResetPassword'
import ResetPasswordForm from '../Auth/ResetPasswordForm'

// Finances
import FinancesHome from '../../features/Dashboard'
import TransactionsPanel from '../../features/Transactions'
import UserTransactionsByCategory from '../Finances/TransactionsPanel/UserTransactionsByCategory'
import ProfitAndLossPanel from '../../features/Reports/ProfitAndLossPanel'
import BookkeepingReportPanel, {
  EoyAlertBanner,
} from '../Finances/Reports/BookkeepingReportPanel'
import ExpensesPanel from '../Finances/ExpensesPanel'
import InsightsExpensesPanel from '../Finances/InsightsExpensesPanel'
import InsightsIncomePanel from '../Finances/InsightsIncomePanel'
import AnnualTaxPanel from '../../features/Taxes/AnnualTaxes/Overview'
import QuarterlyTaxPanel from '../../features/Taxes/QuarterlyTaxEstimates'
import EstimateBreakdown from '../../features/Taxes/QuarterlyTaxEstimates/components/EstimateBreakdown/EstimateBreakdown'
import AnnualTaxSurvey from '../../features/Taxes/AnnualTaxes/Survey/AnnualTaxSurvey'
import Ten99FilingAddContractorsPanel from '../../features/Taxes/AnnualTaxes/Ten99Filing/AddContractorsPanel'
import Ten99FilingContractorDetailAnd1099Panel from '../../features/Taxes/AnnualTaxes/Ten99Filing/ContractorDetailAnd1099Panel'
import Ten99FilingVerifyPayerInfoPanel from '../../features/Taxes/AnnualTaxes/Ten99Filing/VerifyPayerInfoPanel'
import Ten99FilingDonePanel from '../../features/Taxes/AnnualTaxes/Ten99Filing/DonePanel'
import FinancesAccounts from '../Finances/Accounts'
import CanceledAccount from '../Finances/Accounts/CanceledAccount'
import FinancesNewAccounts from '../Finances/Accounts/NewAccount'
import AllocationGuidePanel from '../AllocationGuides'
import AllocationCreation from '../AllocationGuides/AllocationCreation'
import SingleAllocationGuidePanel from '../AllocationGuides/SingleAllocationGuidePanel'

// Finances Admin
import AdminFinancesPanel from '../Admin'
import AdminListAllUsersPanel from '../Admin/PaginatedUsersTable/ListAllUsersPanel'
import AllInternalUsersPanel from '../Admin/AllInternalUsersPanel'
import AdminAllBookkeepingReportsPanel from '../Admin/AllBookkeepingReportsPanel'
import AdminAllocationGuidePanel from '../Admin/AllocationGuidePanel'
import AdminIncompleteBookkeepingReportsPanel from '../Admin/IncompleteBookkeepingReportsPanel'
import AdminUserTransactionsPanel from '../Admin/UserTransactionPanel'
import AdminUserInsightsPanel from '../Admin/UserInsightsPanel'
import TransactionCategoriesPanel from '../../features/Admin/TransactionCategories/TransactionCategoriesPanel'
import AdminTransactionRulesPanel from '../Admin/Autocategorization/TransactionRulesPanel'
import AdminUserRecordPanel from '../Admin/UserRecord'
import AdminProfitAndLossPanel from '../../features/Reports/Admin/AdminProfitAndLossPanel'
import AdminUserImportedTransactionsPanel from '../Admin/UserTransactionsPanels/ImportedTransactionsPanel'
import AdminMonthlyBooksPanel from '../Admin/UserTransactionsPanels/MonthlyBooksPanel'
import AllActionItemsPanel from '../../features/Admin/ActionItems/AllActionItemsPanel'
import AdminUserDocumentCategoriesPanel from '../../features/Admin/UserDocumentCategories'
import AdminReconciliationPanel from '../Admin/Reconciliation/Screens/ReconciliationPanel'
import BookkeepingCRM from '../../features/Admin/BookkeepingCRM'
import AdminBalanceSheetPanel from '../../features/Reports/Admin/AdminBalanceSheet/AdminBalanceSheetPanel'
import QAPanel from '../../features/Admin/QA'

// Finances Signup
import FinancesLogin from '../Login/Login'
import AnnualTaxesPanel from '../../features/Admin/Taxes'
import AboundTaxFilingsTable from '../../features/Admin/Taxes/Abound'
import ZendeskBulkMessagePanel from '../../features/Admin/ZendeskBulkMessages/index'
import AdminUserCallsPanel from '../../features/Checkins/Admin/AdminUserCallsPanel'
import AdminProfile from '../../features/Admin/AdminProfile'
import PayrollSettings from '../../features/Payroll/Settings'
import PayrollEmployees from '../../features/Payroll/Employees'
import Employee from '../../features/Payroll/Employee'
import Contractor from '../../features/Payroll/Contractor'
import RunPayroll from '../../features/Payroll/RunPayroll'
import PayrollHistory from '../../features/Payroll/History'
import Enroll from '../../features/Payroll/Enroll'
import CreateTeamMember from '../../features/Payroll/CreateTeamMember'
import CreateEmployee from '../../features/Payroll/CreateTeamMember/CreateEmployee'
import CreateContractor from '../../features/Payroll/CreateTeamMember/CreateContractor'
import MigratePayroll from '../../features/Payroll/Migrate'
import ProcessMigration from '../../features/Payroll/Migrate/ProcessMigration'
import PayrollHistoryDetails from '../../features/Payroll/History/PayrollHistoryDetails'
import ProcessPayroll from '../../features/Payroll/RunPayroll/EmployeePayroll/ProcessPayroll'
import OffCyclePayroll from '../../features/Payroll/RunPayroll/EmployeePayroll/OffCyclePayroll'
import PayrollDocuments from '../../features/Payroll/PayrollDocuments'
import PayrollOverview from '../../features/Payroll/Enroll/PayrollOverview'
import PayrollCompanySetup from '../../features/Payroll/Enroll/PayrollCompanySetup'
import PayrollEnrollLocations from '../../features/Payroll/Enroll/PayrollEnrollLocations'
import PayrollEnrollEmployee from '../../features/Payroll/Enroll/PayrollEnrollEmployee'
import PayrollEnrollSchedule from '../../features/Payroll/Enroll/PayrollEnrollSchedule'
import PayrollEnrollFederal from '../../features/Payroll/Enroll/PayrollEnrollFederal'
import PayrollEmployeeState from '../../features/Payroll/Enroll/PayrollEmployeeState'
import PayrollEnrollBank from '../../features/Payroll/Enroll/PayrollEmployeeBank'
import PayrollEnrollGustoFlow from '../../features/Payroll/Enroll/PayrollEnrollGustoFlow'
import PayrollEnrollComplete from '../../features/Payroll/Enroll/PayrollEnrollComplete'
import ContractorPayroll from '../../features/Payroll/RunPayroll/ContractorPayroll/ContractorPayroll'
import PayrollHistoryContractorDetails from '../../features/Payroll/History/PayrollContractorHistoryDetails'
import CreateEmployeeJob from '../../features/Payroll/CreateTeamMember/CreateEmployeeJob'
import PayrollEmployeeFederal from '../../features/Payroll/Enroll/PayrollEmployeeFederal'
import QuarterlyTaxCalculationsPanel from '../../features/Admin/UserTaxCalculations'
import QuarterlyTaxEstimateDetailsPanel from '../../features/Admin/QuarterlyTaxEstimateDetails'
import AnnualTaxDetailsPanel from '../../features/Admin/AnnualTaxDetails'
import PayrollEnrollEmployeeJob from '../../features/Payroll/Enroll/PayrollEnrollEmployeeJob'
import Storybook from '../../features/Admin/Storybook'
import TaxChecklistDocuments from '../../features/Taxes/AnnualTaxes/TaxChecklist/Documents'

import NotFound from '../pages/NotFound'

import { GEP_ENROLL_PATHS } from '../../features/Payroll/helpers'
import { User } from '../../reducers/auth/userReducer'
import { MASS_MESSAGE_ADMIN } from '../../utils/adminHelpers'
import YearlyReportsPanel from '../Finances/Reports/YearlyReportsPanel'
import AboutYou from '../../features/Signup/AboutYou'
import PracticePriorities from '../../features/Signup/YourPractice/PracticePriorities'
import AboutYourPractice from '../../features/Signup/YourPractice/AboutYourPractice'
import DisqualifiedSignup from '../../features/Signup/YourPractice/DisqualifiedSignup'
import SignupWaitlist from '../../features/Signup/YourPractice/SignupWaitlist'
import PracticeJourney from '../../features/Signup/YourPractice/PracticeJourney'
import ConnectBank from '../../features/Signup/ConnectBank'
import ChoosePlan from '../../features/Signup/ChoosePlan'
import { SIGNUP_PATHS } from '../../features/Signup/helpers'
import AccountSuspended from '../../features/AccountSuspended'
import SignupSuccess from '../../features/Signup/SignupSuccess'
import AdminGeneralLedgerPanel from '../../features/Reports/Admin/AdminGeneralLedgerPanel'
import AdminGeneralJournalPanel from '../../features/Reports/Admin/AdminGeneralJournalPanel'
import AnnualTaxFilingUserPanel from '../../features/Admin/Taxes/AnnualTaxFilingUserPanel'
import PayrollUsers from '../../features/Payroll/PayrollUsers'
import PracticeProfile from '../../features/PracticeProfile'
import YourBusinessForm from '../../features/PracticeProfile/YourBusinessForm'
import TaxInformationForm from '../../features/PracticeProfile/TaxInformationForm'
import PersonalInformationForm from '../../features/PracticeProfile/PersonalInformationForm'
import Referrals from '../../features/Referrals'
import TaxesProfileFlow from '../../features/Taxes/TaxesProfile/FormFlow'
import StateIncomeTaxDetailsPanel from '../../features/Admin/Taxes/IncomeTaxDetails/StateIncomeTaxDetails'
import FederalIncomeTaxDetailsPanel from '../../features/Admin/Taxes/IncomeTaxDetails/FederalIncomeTaxDetails'
import DirectoryPanel from './DirectoryPanel'
import { isProduction } from '../../utils/envHelpers'
import type { Colors } from '../../styles/theme'
import UserDocuments from '../../features/UserDocuments'
import { OnboardingV2 } from '../../features/Onboarding'
import PayrollUpsell from '../../features/Payroll/Upsell'
import TaxYearTimeline from '../../features/Taxes/AnnualTaxes/components/TaxYearTimeline'
import LifeChanges from '../../features/Taxes/AnnualTaxes/TaxChecklist/Form1040/LifeChanges'
import TaxQuestionnaire1120S from '../../features/Taxes/AnnualTaxes/TaxChecklist/Form1120S'
import AdminTaxChecklist from '../../features/Taxes/AnnualTaxes/TaxChecklist/Admin'
import TaxChecklist from '../../features/Taxes/AnnualTaxes/TaxChecklist/TaxChecklist'
import Form1040Details from '../../features/Taxes/AnnualTaxes/TaxChecklist/Form1040/Details'
import AddMissingQTEPayments from '../../features/Taxes/AnnualTaxes/TaxChecklist/Form1040/AddMissingQTEPayments/AddMissingQTEPayments'
import DeductionsAndCredits from '../../features/Taxes/AnnualTaxes/TaxChecklist/Form1040/DeductionsAndCredits'
import PreviousYearTaxForm from '../../features/Signup/PreviousYearTax/PreviousYearTaxForm'
import ReviewIncomeAndExpenses from '../../features/Taxes/AnnualTaxes/TaxChecklist/Shared/ReviewIncomeAndExpenses'
import UpdateYourBooks from '../../features/Taxes/AnnualTaxes/TaxChecklist/UpdateYourBooks/UpdateYourBooks'
import IncomeAndLiabilities from '../../features/Taxes/AnnualTaxes/TaxChecklist/Form1040/IncomeAndLiabilities'
import { TaxChecklistPaths } from '../../features/Taxes/AnnualTaxes/constants'
import ExtensionSurveyFormBusiness from '../../features/Taxes/AnnualTaxes/ExtensionSurvey/Business'
import ExtensionSurveyFormPersonal from '../../features/Taxes/AnnualTaxes/ExtensionSurvey/Personal'
import ExtensionSurveyFormCombined from '../../features/Taxes/AnnualTaxes/ExtensionSurvey/Combined'
import EmailUsers from '../../features/Admin/EmailUsers'
import InsightsProfitPanel from '../Finances/InsightsProfitPanel'
import RecalculationProfileFlow from '../../features/Taxes/TaxesProfile/RecalculationFormFlow'
import BookkeepingReportReroute from '../Finances/Reports/BookkeepingReportReroute'
import SetupLandingCard from '../../features/Onboarding/SetupLanding/SetupLandingCard'
import { SetupBankAccountPreScreenCard } from '../../features/Onboarding/ConnectStage/SetupBankAccountPreScreenCard'
import { ConnectBusinessAccounts } from '../../features/Onboarding/ConnectStage/ConnectBusinessAccounts'
import { SetupComplete } from '../../features/Onboarding/SetupComplete'
import CalculateLateHarborFlow from '../../features/Taxes/QuarterlyTaxEstimates/components/CalculateSafeHarborFlow'
import ExtensionSurveyReadOnly from '../../features/Taxes/AnnualTaxes/ExtensionSurvey/ExtensionSurveyReadOnly'
import MaintenanceBanner, {
  MaintenanceBannerWithBorder,
} from '../Banners/MaintenanceBanner'
import ZendeskSso from '../../features/Zendesk/ZendeskSso'
import FinancialAdvisoryOverview from '../../features/FinancialAdvisory'
import FinancialAdvisoryGoals from '../../features/FinancialAdvisory/panels/GoalsPanel'
import FinancialAdvisoryInbox from '../../features/FinancialAdvisory/panels/InboxPanel'
import { RedirectToExternal } from './RedirectToExternal'
import BookkeepingFlow from '../../features/Onboarding/AhaMoments/Bookkeeping'
import CatchUpBookkeepingUnpaid from '../../features/AccountSuspended/CatchUpBookkeepingUnpaid'
import SelfServeCancellationFlow from '../../features/SelfServeCancellationFlow'
import { EndOfYearReviewContainer } from '../../features/Bookkeeping/end-of-year-review'
import OnboardingDashboard from '../../features/Dashboard/OnboardingDashboard'
import BookConsult from '../../features/Signup/YourPractice/BookConsult'
import TaxesFlow from '../../features/Onboarding/AhaMoments/Taxes'
import HeardCommunityBanner from '../../features/Dashboard/HeardCommunityBanner'
import EstimateBreakdownSample from '../../features/Taxes/QuarterlyTaxEstimates/components/EstimateBreakdown/EstimateBreakdownSample'
import YearEndKickoffSurvey from '../../features/Taxes/AnnualTaxes/TaxSeasonKickoff/YearEndKickoffSurvey'
import QTEReminderBanner from '../../features/Taxes/QuarterlyTaxEstimates/QTEReminderBanner'
import PastReturnsPanel from '../../features/Taxes/AnnualTaxes/Overview/PastReturnsPanel'

export type RouteConfig = {
  [key: string]: Omit<RouteProps, 'element' | 'path' | 'index' | 'lazy'> & {
    component: ElementType
    customAuth?: (user: User) => boolean
    banner?: ElementType | ElementType[]
    backgroundColor?: keyof typeof Colors
    innerContentWrapperStyle?: CSSProperties
    contentWrapperStyle?: CSSProperties
  }
}
export const EXTERNAL_ADMIN_ROUTES: RouteConfig = {
  '/admin/finances/list-users': {
    component: AdminListAllUsersPanel,
  },
  '/admin/profile': {
    component: AdminProfile,
  },
  '/admin/finances': {
    component: AdminFinancesPanel,
  },
  '/admin/finances/annual-tax-filings': {
    component: AnnualTaxesPanel,
    backgroundColor: 'white',
  },
  '/admin/finances/annual-tax-filings/:filingId': {
    component: AnnualTaxFilingUserPanel,
  },
  '/admin/finances/transactions/:userId': {
    component: AdminUserTransactionsPanel,
  },
  '/admin/finances/records/:userId/monthly-books/:reportId': {
    component: AdminMonthlyBooksPanel,
  },
  '/admin/finances/financial-reports/:userId/profit-loss': {
    component: AdminProfitAndLossPanel,
  },
  '/admin/finances/financial-reports/:userId/general-ledger': {
    component: AdminGeneralLedgerPanel,
  },
  '/admin/finances/financial-reports/:userId/general-journal': {
    component: AdminGeneralJournalPanel,
  },
  '/admin/finances/financial-reports/:userId/balance-sheet': {
    component: AdminBalanceSheetPanel,
  },
  '/admin/finances/transactions/:userId/imported': {
    component: AdminUserImportedTransactionsPanel,
  },
  '/admin/finances/records/:userId': {
    component: AdminUserRecordPanel,
  },
  '/admin/finances/records/:userId/calls': {
    component: AdminUserCallsPanel,
  },
  '/admin/finances/records/:userId/allocation-guides/:allocationGuideId': {
    component: AdminAllocationGuidePanel,
  },
}

export const ADMIN_ROUTES: RouteConfig = {
  '/admin/finances/all-internal-users': {
    component: AllInternalUsersPanel,
  },
  '/admin/finances/mass-messaging': {
    customAuth: (user: User) => MASS_MESSAGE_ADMIN(user),
    component: ZendeskBulkMessagePanel,
  },
  'admin/finances/email-users': {
    component: EmailUsers,
  },
  '/admin/finances/books/crm': {
    component: BookkeepingCRM,
  },
  '/admin/finances/books': {
    component: AdminAllBookkeepingReportsPanel,
  },
  '/admin/finances/books/incomplete': {
    component: AdminIncompleteBookkeepingReportsPanel,
  },
  '/admin/finances/transaction-categories': {
    component: TransactionCategoriesPanel,
  },
  '/admin/finances/transaction-rules': {
    component: AdminTransactionRulesPanel,
  },

  '/admin/finances/insights/:userId': {
    component: AdminUserInsightsPanel,
  },
  '/admin/finances/all-action-items': {
    component: AllActionItemsPanel,
  },
  '/admin/finances/user-document-categories': {
    component: AdminUserDocumentCategoriesPanel,
  },
  '/admin/finances/quarterly-tax-estimate-details': {
    component: QuarterlyTaxEstimateDetailsPanel,
  },
  '/admin/finances/annual-tax-details': {
    component: AnnualTaxDetailsPanel,
  },

  '/admin/finances/abound-tax-filings': {
    component: AboundTaxFilingsTable,
  },
  '/admin/finances/qte-calculations': {
    component: QuarterlyTaxCalculationsPanel,
  },
  '/admin/finances/federal-income-tax': {
    component: FederalIncomeTaxDetailsPanel,
  },
  '/admin/finances/state-income-tax': {
    component: StateIncomeTaxDetailsPanel,
  },
  '/admin/finances/tax-checklist': {
    component: AdminTaxChecklist,
  },
  '/admin/finances/records/:userId/monthly-books/:reportId/reconciliation/:reconciliationId':
    {
      component: AdminReconciliationPanel,
    },
  '/admin/storybook': {
    component: Storybook,
  },
  '/admin/finances/qa': {
    component: QAPanel,
  },
  '/admin/payroll/users': {
    component: PayrollUsers,
  },
}

export const PRIVATE_ROUTES: RouteConfig = {
  '/': {
    component: FinancesHome,
    banner: [MaintenanceBanner, QTEReminderBanner],
    backgroundColor: 'white',
  },
  '/dashboard': {
    component: FinancesHome,
    banner: [MaintenanceBanner, QTEReminderBanner, HeardCommunityBanner],
    backgroundColor: 'white',
  },
  '/onboarding-dashboard': {
    component: OnboardingDashboard,
    banner: [MaintenanceBannerWithBorder, QTEReminderBanner],
    backgroundColor: 'white',
    innerContentWrapperStyle: {
      padding: 0,
    },
  },
  '/onboarding': {
    component: OnboardingV2,
    backgroundColor: 'white',
  },
  '/onboarding-v2': {
    component: OnboardingV2,
    backgroundColor: 'white',
  },
  '/onboarding-v2/complete': {
    component: SetupComplete,
    backgroundColor: 'white',
  },
  '/onboarding-v2/learning/bookkeeping': {
    component: BookkeepingFlow,
    backgroundColor: 'white',
  },
  '/onboarding-v2/learning/taxes': {
    component: TaxesFlow,
    backgroundColor: 'white',
  },
  '/onboarding-v2/estimate-breakdown-sample': {
    component: EstimateBreakdownSample,
    backgroundColor: 'white',
  },
  '/onboarding/setup/landing': {
    component: SetupLandingCard,
    backgroundColor: 'white',
  },
  '/onboarding/setup/prescreen': {
    component: SetupBankAccountPreScreenCard,
    backgroundColor: 'white',
  },
  '/onboarding/setup/connect-bank': {
    component: ConnectBusinessAccounts,
    backgroundColor: 'white',
  },
  '/accounts/zendesk/sso': {
    component: ZendeskSso,
    backgroundColor: 'white',
  },
  '/accounts': {
    component: FinancesAccounts,
  },
  '/accounts/choose-plan': {
    component: () => <ChoosePlan isUpsell />,
  },

  '/accounts/canceled': {
    component: CanceledAccount,
  },
  '/accounts/verify/:token': {
    component: FinancesAccounts,
  },
  '/accounts/new': {
    component: FinancesNewAccounts,
  },
  '/accounts/new/oauth': {
    component: FinancesNewAccounts,
  },
  '/accounts/suspended': {
    component: AccountSuspended,
  },
  '/transactions': {
    component: TransactionsPanel,
    backgroundColor: 'white',
  },
  '/transactions/:type': {
    component: TransactionsPanel,
    backgroundColor: 'white',
  },
  '/transactions/expenses/:transactionCategoryId': {
    component: UserTransactionsByCategory,
  },
  '/bookkeeping/end-of-year-review': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/other-expenses': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/other-income': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/unclarified-transactions': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/owners-investments': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/owners-distributions': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/business-income-in-personal-accounts':
    {
      component: EndOfYearReviewContainer,
      backgroundColor: 'white',
    },
  '/bookkeeping/end-of-year-review/:year/business-expenses-in-personal-accounts':
    {
      component: EndOfYearReviewContainer,
      backgroundColor: 'white',
    },
  '/bookkeeping/end-of-year-review/:year/all-done': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/intro': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/disconnected-accounts': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/contractor-documents': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/review': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/review-income': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/review-expenses': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/payroll-documents': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/bookkeeping/end-of-year-review/:year/missing-bank-statements': {
    component: EndOfYearReviewContainer,
    backgroundColor: 'white',
  },
  '/reports/bookkeeping/:reportName': {
    component: BookkeepingReportPanel,
    banner: EoyAlertBanner,
    backgroundColor: 'white',
  },
  '/reports/bookkeeping/:bookkeepingReportMonth/:bookkeepingReportYear/:reportName':
    {
      component: BookkeepingReportPanel,
      banner: EoyAlertBanner,
      backgroundColor: 'white',
    },
  '/reports/bookkeeping/:bookkeepingReportId/:reportName': {
    component: BookkeepingReportReroute,
    backgroundColor: 'white',
  },
  '/reports/yearly': {
    component: YearlyReportsPanel,
    backgroundColor: 'white',
  },
  '/reports/profit-loss': {
    component: ProfitAndLossPanel,
    backgroundColor: 'white',
  },
  '/trends/expenses': {
    component: ExpensesPanel,
    backgroundColor: 'white',
  },
  '/insights/expenses': {
    component: InsightsExpensesPanel,
    backgroundColor: 'white',
  },
  '/insights/income': {
    component: InsightsIncomePanel,
    backgroundColor: 'white',
  },
  '/insights/profit': {
    component: InsightsProfitPanel,
    backgroundColor: 'white',
  },
  '/taxes/annual': {
    component: AnnualTaxPanel,
    backgroundColor: 'white',
  },
  '/taxes/archive': {
    component: PastReturnsPanel,
    backgroundColor: 'white',
  },

  '/taxes': {
    component: () => <Navigate replace to="/taxes/annual" />,
  },
  '/taxes/quarterly': {
    component: QuarterlyTaxPanel,
    backgroundColor: 'white',
  },
  '/taxes/annual/annual_tax_needs/:year': {
    component: AnnualTaxSurvey,
    backgroundColor: 'white',
  },
  '/taxes/annual/kickoff-survey': {
    component: YearEndKickoffSurvey,
    backgroundColor: 'white',
  },
  '/taxes/annual/file_1099_nec/verify_business': {
    component: Ten99FilingVerifyPayerInfoPanel,
    backgroundColor: 'white',
  },
  '/taxes/annual/file_1099_nec/contractors': {
    component: Ten99FilingAddContractorsPanel,
    backgroundColor: 'white',
  },
  '/taxes/annual/file_1099_nec/contractors/:contractorUUID': {
    component: Ten99FilingContractorDetailAnd1099Panel,
    backgroundColor: 'white',
  },
  '/taxes/annual/file_1099_nec/done': {
    component: Ten99FilingDonePanel,
    backgroundColor: 'white',
  },
  '/taxes/annual/timeline/:year': {
    component: TaxYearTimeline,
    backgroundColor: 'white',
  },
  '/taxes/annual/tax_checklist/': {
    component: TaxChecklist,
    backgroundColor: 'white',
  },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.addMissingQtePayments}`]:
    {
      component: AddMissingQTEPayments,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.reviewIncomeExpense}`]:
    {
      component: ReviewIncomeAndExpenses,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.lifeChanges}`]: {
    component: LifeChanges,
    backgroundColor: 'white',
  },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.deductionsAndCredits}`]:
    {
      component: DeductionsAndCredits,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.incomeAndLiabilities}`]:
    {
      component: IncomeAndLiabilities,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.checkYourDetails}`]:
    {
      component: TaxQuestionnaire1120S,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.checkYourDetailsPersonal}`]:
    {
      component: Form1040Details,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.updateYourBooks}`]:
    {
      component: UpdateYourBooks,
      backgroundColor: 'white',
    },
  [`/taxes/annual/tax_checklist/:formId/${TaxChecklistPaths.documents}`]: {
    component: TaxChecklistDocuments,
    backgroundColor: 'white',
  },
  '/taxes/annual/extension_request/business': {
    component: ExtensionSurveyFormBusiness,
    backgroundColor: 'white',
  },
  '/taxes/annual/extension_request/personal': {
    component: ExtensionSurveyFormPersonal,
    backgroundColor: 'white',
  },
  '/taxes/annual/extension_request/combined': {
    component: ExtensionSurveyFormCombined,
    backgroundColor: 'white',
  },
  '/taxes/annual/extension_request/complete/:taxYear': {
    component: ExtensionSurveyReadOnly,
    backgroundColor: 'white',
  },
  '/taxes/quarterly/recalculate': {
    component: RecalculationProfileFlow,
    backgroundColor: 'white',
  },
  '/taxes/quarterly/calculate-safe-harbor': {
    component: CalculateLateHarborFlow,
    backgroundColor: 'white',
  },
  '/taxes/quarterly/:taxYear': {
    component: QuarterlyTaxPanel,
  },
  '/taxes/quarterly/:taxQuarter/estimate-breakdown': {
    component: EstimateBreakdown,
    backgroundColor: 'white',
  },
  '/taxes/quarterly/:taxYear/:taxQuarter': {
    component: QuarterlyTaxPanel,
  },
  '/practice/profile': {
    component: PracticeProfile,
    backgroundColor: 'white',
  },
  '/practice/profile/editBusiness': {
    component: YourBusinessForm,
    backgroundColor: 'white',
  },
  // Backwards compat link
  '/practice/profile/:profileId/edit': {
    component: () => <Navigate replace to="/practice/profile/editBusiness" />,
  },
  // Backwards compat link
  '/practice/profile/edit': {
    component: YourBusinessForm,
    backgroundColor: 'white',
  },
  '/practice/profile/editTax': {
    component: TaxInformationForm,
    backgroundColor: 'white',
  },
  '/practice/profile/editPersonal': {
    component: PersonalInformationForm,
    backgroundColor: 'white',
  },
  '/messages': {
    component: () => (
      <RedirectToExternal
        to={`https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests?utf8=%E2%9C%93&query=&status=answered`}
      />
    ),
  },
  '/conversations': {
    component: () => (
      <RedirectToExternal
        to={`https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests?utf8=%E2%9C%93&query=&status=answered`}
      />
    ),
    backgroundColor: 'white',
  },
  '/conversations/:id': {
    component: () => (
      <RedirectToExternal
        to={`https://${process.env.VITE_ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests?utf8=%E2%9C%93&query=&status=answered`}
      />
    ),
    backgroundColor: 'white',
    innerContentWrapperStyle: {
      padding: 0,
    },
  },
  '/practice/documents': {
    component: UserDocuments,
    backgroundColor: 'white',
  },
  '/practice/documents/:tabName/:action': {
    component: UserDocuments,
    backgroundColor: 'white',
  },
  '/allocation-guides': {
    component: AllocationGuidePanel,
    backgroundColor: 'white',
  },
  '/allocation-guides/view/:allocationGuideId': {
    component: SingleAllocationGuidePanel,
    backgroundColor: 'white',
  },
  '/allocation-guides/:state': {
    component: AllocationCreation,
    backgroundColor: 'white',
  },
  '/taxes/profile': {
    component: TaxesProfileFlow,
  },
  '/user-cancellation': {
    component: SelfServeCancellationFlow,
    backgroundColor: 'white',
  },
  '/rewards': {
    component: Referrals,
  },
  '/payroll/welcome': {
    component: PayrollUpsell,
  },
  '/advisory/overview': {
    component: FinancialAdvisoryOverview,
    backgroundColor: 'white',
  },
  '/advisory/enroll': {
    component: FinancialAdvisoryOverview,
    backgroundColor: 'white',
  },
  '/advisory/goals': {
    component: FinancialAdvisoryGoals,
    backgroundColor: 'white',
  },
  '/advisory/messages': {
    component: FinancialAdvisoryInbox,
    backgroundColor: 'white',
  },
  '/accounts/suspended/catch-up-bookkeeping': {
    component: CatchUpBookkeepingUnpaid,
    backgroundColor: 'white',
  },
  '*': {
    component: NotFound,
  },
}

export const PAYROLL_ROUTES: RouteConfig = {
  [GEP_ENROLL_PATHS.intro]: {
    component: Enroll,
  },
  [GEP_ENROLL_PATHS.overview]: {
    component: PayrollOverview,
  },
  [GEP_ENROLL_PATHS.company]: {
    component: PayrollCompanySetup,
  },
  [GEP_ENROLL_PATHS.federal]: {
    component: PayrollEnrollFederal,
  },
  [GEP_ENROLL_PATHS.locations]: {
    component: PayrollEnrollLocations,
  },
  [GEP_ENROLL_PATHS.employee]: {
    component: PayrollEnrollEmployee,
  },
  [GEP_ENROLL_PATHS.employeeJob]: {
    component: PayrollEnrollEmployeeJob,
  },
  [GEP_ENROLL_PATHS.employeeFederal]: {
    component: PayrollEmployeeFederal,
  },
  [GEP_ENROLL_PATHS.employeeState]: {
    component: PayrollEmployeeState,
  },
  [GEP_ENROLL_PATHS.employeeState]: {
    component: PayrollEmployeeState,
  },
  [GEP_ENROLL_PATHS.employeeBank]: {
    component: PayrollEnrollBank,
  },
  [GEP_ENROLL_PATHS.schedule]: {
    component: PayrollEnrollSchedule,
  },
  [GEP_ENROLL_PATHS.flow]: {
    component: PayrollEnrollGustoFlow,
  },
  [GEP_ENROLL_PATHS.complete]: {
    component: PayrollEnrollComplete,
  },
  '/payroll/migrate': {
    component: MigratePayroll,
  },
  '/payroll/process_migration': {
    component: ProcessMigration,
  },
  '/payroll/settings': {
    component: PayrollSettings,
  },
  '/payroll/employees': {
    component: PayrollEmployees,
  },
  '/payroll/employee/:uuid': {
    component: Employee,
  },
  '/payroll/create_team_member': {
    component: CreateTeamMember,
  },
  '/payroll/create_employee': {
    component: CreateEmployee,
  },
  '/payroll/create_employee/job/:uuid': {
    component: CreateEmployeeJob,
  },
  '/payroll/create_contractor': {
    component: CreateContractor,
  },
  '/payroll/contractor/:uuid': {
    component: Contractor,
  },
  '/payroll/run_payroll': {
    component: RunPayroll,
  },
  '/payroll/run_payroll/process/:uuid': {
    component: ProcessPayroll,
  },
  '/payroll/run_contractor_payroll': {
    component: ContractorPayroll,
  },
  '/payroll/create_off_cycle_payroll': {
    component: OffCyclePayroll,
  },
  '/payroll/history': {
    component: PayrollHistory,
  },
  '/payroll/history/:uuid': {
    component: PayrollHistoryDetails,
  },
  '/payroll/contractor_history/:uuid': {
    component: PayrollHistoryContractorDetails,
  },
  '/payroll/documents': {
    component: PayrollDocuments,
  },
}

export const PUBLIC_ROUTES: RouteConfig = {
  '/recover': {
    component: ResetPassword,
  },
  '/reset/:token': {
    component: ResetPasswordForm,
  },
  '/login': {
    component: FinancesLogin,
  },
  '/zendesk/sso': {
    component: FinancesLogin,
  },
  [SIGNUP_PATHS.referred]: {
    component: AboutYou,
  },
  [SIGNUP_PATHS.aboutYou]: {
    component: AboutYou,
  },
  [SIGNUP_PATHS.practicePriorities]: {
    component: PracticePriorities,
  },
  [SIGNUP_PATHS.aboutYourPractice]: {
    component: AboutYourPractice,
  },
  [SIGNUP_PATHS.disqualified]: {
    component: DisqualifiedSignup,
  },
  [SIGNUP_PATHS.waitlist]: {
    component: SignupWaitlist,
  },
  [SIGNUP_PATHS.consult]: {
    component: BookConsult,
  },
  [SIGNUP_PATHS.practiceJourney]: {
    component: PracticeJourney,
  },
  [SIGNUP_PATHS.connectBank]: {
    component: ConnectBank,
  },
  [SIGNUP_PATHS.previousYearTax]: {
    component: PreviousYearTaxForm,
  },
  [SIGNUP_PATHS.choosePlan]: {
    component: ChoosePlan,
  },
  [SIGNUP_PATHS.success]: {
    component: SignupSuccess,
  },
  '/directory': {
    component: isProduction() ? NotFound : DirectoryPanel,
  },
}

export const ALL_ROUTES = {
  ...EXTERNAL_ADMIN_ROUTES,
  ...ADMIN_ROUTES,
  ...PRIVATE_ROUTES,
  ...PAYROLL_ROUTES,
  ...PUBLIC_ROUTES,
}
