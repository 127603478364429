import { CSSProperties, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Colors } from '../../styles/theme'
import Icon from './Icon'
import Text from './Text'
import { DeviceWidth, useIsDeviceWidth } from '../../utils/deviceWidthHelpers'

export type AlertType =
  | 'announcement'
  | 'error'
  | 'info'
  | 'warn'
  | 'success'
  | 'acknowledge'

const IconMap = {
  announcement: solid('circle-info'),
  error: solid('circle-exclamation'),
  info: solid('circle-info'),
  warn: solid('triangle-exclamation'),
  success: solid('check'),
  acknowledge: solid('check'),
}

const ColorMap: Record<
  AlertType,
  { backgroundColor: string; border: string; color: string }
> = {
  announcement: {
    border: 'none !important',
    backgroundColor: Colors.moss,
    color: Colors.black,
  },
  error: {
    border: `1px solid ${Colors.red} !important`,
    backgroundColor: Colors.lightRed,
    color: Colors.red,
  },
  info: {
    border: 'none !important',
    backgroundColor: Colors.stone,
    color: Colors.black,
  },
  warn: {
    border: `1px solid ${Colors.orange} !important`,
    backgroundColor: Colors.lightOrange,
    color: Colors.orange,
  },
  acknowledge: {
    border: 'none',
    backgroundColor: Colors.lightOrange,
    color: Colors.orange,
  },
  success: {
    border: `1px solid ${Colors.green} !important`,
    backgroundColor: Colors.lightGreen,
    color: Colors.green,
  },
}

interface Props {
  children?: ReactNode
  className?: string
  customIcon?: ReactNode
  closeable?: boolean
  fullWidth?: boolean
  style?: CSSProperties
  contentStyle?: CSSProperties
  innerTextStyle?: CSSProperties
  title?: string
  type?: AlertType
  // When cards are in a mobile view they often should be flush with the sides rather than have the standard padding of the container
  // This is that offset (should be negative)
  unsetMobileMargin?: number

  /**
   * If true, the icon and content will stack on mobile
   */
  stackOnMobile?: boolean

  /**
   * This will store the dismissal in local storage under the specified key
   * and will not show the alert again if the key is present
   */
  permanentDismissalKey?: string

  /**
   * If true, children will be rendered as is without any additional styling or structure
   */
  useCustomContent?: boolean

  /**
   * Callback for when the alert is closed
   */
  onClose?: () => void
}

const Alert = styled(
  ({
    children,
    className,
    closeable,
    customIcon,
    title,
    style,
    contentStyle,
    innerTextStyle,
    permanentDismissalKey,
    type = 'info',
    useCustomContent = false,
    onClose = () => {},
  }: Props) => {
    const [open, setIsOpen] = useState(
      permanentDismissalKey
        ? !localStorage.getItem(permanentDismissalKey)
        : true
    )
    const isMobile = useIsDeviceWidth(DeviceWidth.mobile)

    if (!open) {
      return null
    }

    return (
      <div className={`${className} ${isMobile && 'mobile'}`} style={style}>
        {useCustomContent ? (
          children
        ) : (
          <>
            {customIcon ? (
              customIcon !== 'none' && <div className="icon">{customIcon}</div>
            ) : (
              <Icon icon={IconMap[type]} className="icon" />
            )}
            <div className="content" style={contentStyle}>
              <Text className="alert-title" as="h3">
                {title}
              </Text>
              <Text style={innerTextStyle}>{children}</Text>
            </div>
          </>
        )}
        {closeable && (
          <Icon
            className="close-icon"
            icon={regular('close')}
            onClick={() => {
              setIsOpen(false)
              if (permanentDismissalKey) {
                localStorage.setItem(permanentDismissalKey, 'true')
              }
              onClose()
            }}
          />
        )}
      </div>
    )
  }
)(({ fullWidth, type = 'info', unsetMobileMargin, stackOnMobile }) => {
  const { border, backgroundColor, color } = ColorMap[type]
  return {
    '&&&': {
      position: 'relative',
      alignItems: 'center',
      backgroundColor,
      border,
      borderRadius: '4px',
      display: 'flex',
      padding: 16,
      width: fullWidth ? '100%' : 'initial',
      wordBreak: 'break-word',
      '.icon': {
        color,
        margin: '0 18px 0 2px',
        // For custom icons
        fontSize: 20,
      },

      '.content > .alert-title': {
        marginBottom: 4,
      },

      '.close-icon': {
        position: 'absolute',
        top: 18,
        right: 18,
        cursor: 'pointer',
      },

      '&.mobile': {
        ...(stackOnMobile && {
          flexWrap: 'wrap',
          '.icon': {
            margin: '16px 0',
            marginRight: 'auto',
          },
          '.content': {
            flex: '1 1 auto',
            width: '100%',
          },
        }),

        ...(unsetMobileMargin && {
          marginLeft: unsetMobileMargin,
          marginRight: unsetMobileMargin,
          maxWidth: 'unset',
          width: 'unset',
        }),
      },
    },
  }
})

export default Alert
