import { useEffect, useState } from 'react'
import { Grid, List } from 'semantic-ui-react'

import {
  GridRowColumn,
  Text,
  Alert,
  Checkbox,
} from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import './styles.scss'
import { ReviewStepCard } from '../../../../components/Finances/ReviewStep'
import { IStep } from '../step-map'

export const Review = ({
  userSteps,
  setReviewIndex,
  setIsStepComplete,
}: {
  userSteps: IStep[]
  setReviewIndex: (route: string) => void
  setIsStepComplete: (value: boolean) => void
}) => {
  const [isChecked, setIsChecked] = useState(false)
  const [reviewSteps, setReviewSteps] = useState<IStep[]>(userSteps)
  useEffect(() => {
    const steps = userSteps
    const filteredSteps: IStep[] = []
    for (const step of steps) {
      if (step?.route) {
        if (step?.finalReviewGroup) {
          filteredSteps.push(step)
        }
      }
    }
    setReviewSteps(filteredSteps)
  }, [userSteps])

  const onChangeSkipAccountReconnect = () => {
    const newCheckedState = !isChecked
    setIsChecked(newCheckedState)
    setIsStepComplete(newCheckedState)
  }

  return (
    <Grid className="income-grid">
      <GridRowColumn centerContent className="header-container">
        <StepHeaders
          imageSrc="https://heard-images.s3.amazonaws.com/assets/checklist-confetti.svg"
          imageAlt="checklist-confetti"
          imageWidth={180}
          imageHeight={180}
          title="Almost Done..."
          description=""
          customColor="green"
        >
          <Grid>
            <GridRowColumn className="align-left">
              <Text>
                Please review your answers, as you can’t make any changes or add
                more transactions to your 2024 books after you submit.
              </Text>
            </GridRowColumn>
            <GridRowColumn className="align-left">
              {reviewSteps?.map((step, index) => {
                if (step?.name) {
                  if (
                    step.finalReviewGroup !==
                      (reviewSteps[index + 1]
                        ? reviewSteps[index + 1].finalReviewGroup
                        : '') &&
                    index !== reviewSteps.length - 1
                  ) {
                    return (
                      <>
                        <ReviewStepCard
                          key={step.route}
                          step={step}
                          setReviewIndex={setReviewIndex}
                        />
                        <div className="group-text-card">
                          <Text>{reviewSteps[index + 1].reviewGroupLabel}</Text>
                        </div>
                      </>
                    )
                  } else {
                    return (
                      <>
                        {index === 0 && (
                          <div className="group-text-card">
                            <Text className="text">
                              {step.reviewGroupLabel}
                            </Text>
                          </div>
                        )}
                        <ReviewStepCard
                          key={step.route}
                          step={step}
                          setReviewIndex={setReviewIndex}
                        />
                      </>
                    )
                  }
                }
                return null
              })}
            </GridRowColumn>
          </Grid>
        </StepHeaders>
      </GridRowColumn>
      <GridRowColumn>
        <div className="alert-footer">
          <Alert
            title="You acknowledge that:"
            style={{ width: '520px' }}
            customIcon="none"
            contentStyle={{ textAlign: 'left' }}
            type="acknowledge"
          >
            <List bulleted className="flex-column">
              <List.Item>
                You’ve reviewed all of your answers above. They can’t be
                modified after submission.
              </List.Item>
              <List.Item>
                Your 2024 books will be locked upon submission. You won’t be
                able to add or modify transactions for this year.
              </List.Item>
            </List>
            <Checkbox
              id={'acknowledge'}
              label="I acknowledge the above"
              checked={isChecked}
              onChange={onChangeSkipAccountReconnect}
            />
          </Alert>
        </div>
      </GridRowColumn>
    </Grid>
  )
}

export default Review
