import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { Colors } from '../../../styles/theme'

const getDescription = (
  status: YearEndModuleStatusOptions,
  isTwoFormFiler: boolean
) => {
  switch (status) {
    case YearEndModuleStatusOptions.upcoming: {
      const liveConsultText =
        'Complete your other tasks to reserve a live consultation with your tax preparer.'
      const bodyText = isTwoFormFiler ? (
        <>
          After finishing your business tax return, your tax preparer will help
          you finalize and file your personal tax return.
          <div style={{ paddingTop: 16 }} />
          {liveConsultText}
        </>
      ) : (
        <>
          We’ll connect you with a tax preparer from our partner TaxFyle.
          They’ll work with you to finalize and submit your return.
          <div style={{ paddingTop: 16 }} />
          {liveConsultText}
        </>
      )
      const availableAfterText = isTwoFormFiler
        ? 'Available after filing your business tax return (Form 1120-S).'
        : 'We’ll connect you to a tax preparer after completing all previous tasks.'

      return (
        <UpcomingStatusTextWrapper availableAfterText={availableAfterText}>
          {bodyText}
        </UpcomingStatusTextWrapper>
      )
    }
    default:
      return null
  }
}

const getAlertBody = (dueDate: string | null) => {
  const daysUntilDueDate = getDeadlineApproachingDaysUntilDueDate(dueDate)
  if (daysUntilDueDate === null) {
    return null
  }
  const link = (
    <Link
      href="https://support.joinheard.com/hc/en-us/articles/4421104098583-What-do-I-need-to-do-in-Heard-prior-to-filling-out-my-Tax-Checklist"
      newPage
      size="small"
      style={{
        textDecoration: 'underline',
        color: Colors.charcoal,
        textDecorationColor: Colors.charcoal,
      }}
    >
      More info
    </Link>
  )
  if (daysUntilDueDate <= 0) {
    return (
      <Text as="bodySm">
        After that, you may be at risk of penalties and interest. {link}
      </Text>
    )
  }
  return (
    <Text as="bodySm">
      This tax return is now overdue. You may be at risk of penalties and
      interest. Please file it ASAP to avoid additional fines. {link}
    </Text>
  )
}

const File1040Card = ({
  status,
  dueDate,
  taxYear,
  isTwoFormFiler,
}: YearEndModuleStatusDynamicDataParams & {
  isTwoFormFiler: boolean
}) => {
  const description = getDescription(status, isTwoFormFiler)
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.file1040}
      status={status}
      dueDate={dueDate}
      taxYear={taxYear}
      header="File Personal Tax Return (Form 1040)"
      imageUrl="https://heard-images.s3.amazonaws.com/assets/1040-form.svg"
      description={<Text as="bodySm">{description}</Text>}
      timer={{
        unit: 'minutes',
        value: 25,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: getAlertBody(dueDate),
      }}
      button={{}}
    />
  )
}

export default File1040Card
