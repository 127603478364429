import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Card, IconButton } from '../../BaseComponents'
import { StackedIcon } from '../../BaseComponents/Icon'
import styles from './styles.module.scss'
import { IStep } from '../../../features/Bookkeeping/end-of-year-review/step-map'

export const ReviewStepCard = ({
  step,
  setReviewIndex,
}: {
  step: IStep
  setReviewIndex: (index: string) => void
}) => {
  return (
    <Card key={step.route} backgroundColor={'stone40'} fullWidth>
      <div className={styles.accountCard}>
        <div className={styles.topRow}>
          <StackedIcon
            icon={regular('check')}
            color="green"
            backgroundIcon={solid('circle')}
            backgroundColor="transparent"
            marginRight={10}
          />
          <div className={styles.accountName}>{step.name}</div>
          <IconButton
            icon={regular('edit')}
            color="darkGray"
            onClick={() => {
              setReviewIndex(step.route)
            }}
          />
        </div>
      </div>
    </Card>
  )
}
