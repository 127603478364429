import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Grid, Image } from 'semantic-ui-react'
import { useMemo } from 'react'
import {
  Button,
  Card,
  GridRowColumn,
  Icon,
  Pill,
  Text,
} from '../../../../components/BaseComponents'
import { Colors } from '../../../../styles/theme'
import {
  isoToUTCDateTime,
  DATE_FORMATS_LUXON,
} from '../../../../utils/dateHelpers'
import { useReselector } from '../../../../utils/sharedHooks'
import {
  selectCurrentAnnualTaxDetails,
  selectCurrentAnnualTaxYear,
} from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getCurrentUser } from '../../../../selectors/user.selectors'
import { useNavigate } from 'react-router-dom'
import { getAnnualTaxFilingForYearSelector } from '../annualTaxFilings.selector'
import { useYearEndNextStepPill } from '../../../YearEndModuleStatus/components/EOYNextStepPill'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../../../OpenFeature'

const TaxSeasonKickoffEntryCard = () => {
  const navigate = useNavigate()
  const currentUser = useReselector(getCurrentUser)
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const annualTaxDetails = useReselector(selectCurrentAnnualTaxDetails)
  const annualTaxFiling = useReselector(
    getAnnualTaxFilingForYearSelector,
    taxYear
  )
  const pretaxSurveySubmittedAt = annualTaxFiling?.pretaxSurveySubmittedAt
  const nextStepPill = useYearEndNextStepPill()
  const releaseYearEndHome = useBooleanFlagValue(
    FEATURE_FLAG_KEYS.releaseYearEndHome,
    false
  )
  const cardState = useMemo(() => {
    if (pretaxSurveySubmittedAt && releaseYearEndHome) {
      return {
        // show YE Home data
        headerText: 'File your annual tax return ',
        dueDate:
          annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate ||
          `${taxYear + 1}-04-15`,
        description: (
          <>
            Close out {taxYear} by getting everything in order for annual taxes.
            To avoid penalties, ensure that you have everything completed by{' '}
            <b>
              {isoToUTCDateTime(
                annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate ||
                  `${taxYear + 1}-04-15`
              ).toFormat(DATE_FORMATS_LUXON.DISPLAY_LONG)}
            </b>
          </>
        ),
        nextStepPill: <GridRowColumn>{nextStepPill}</GridRowColumn>,
        button: {
          text: 'Go to Annual Taxes',
          onClick: () => navigate('/taxes/annual'),
        },
      }
    }

    return {
      headerText: 'Get started on annual taxes ',
      dueDate:
        annualTaxDetails?.taxSeasonKickoffDueAt || `${taxYear + 1}-01-09`,
      description: (
        <>
          Complete the Tax Season Kickoff to let us know if you&apos;d like to
          file taxes with Heard. We recommend completing this <b>ASAP</b>. It
          only takes 5 minutes!
        </>
      ),
      nextStepPill: null,
      button: {
        text: 'Start Tax Season Kickoff',
        onClick: () => navigate('/taxes/annual/kickoff-survey'),
      },
    }
  }, [
    annualTaxDetails?.irsFormDueDates.form_1040.irs.dueDate,
    annualTaxDetails?.taxSeasonKickoffDueAt,
    navigate,
    nextStepPill,
    pretaxSurveySubmittedAt,
    releaseYearEndHome,
    taxYear,
  ])

  return (
    <Card
      style={{
        backgroundColor: Colors.natural,
      }}
    >
      <Grid>
        <Grid.Column width={12}>
          <GridRowColumn>
            <Text as="eyebrow" style={{ marginBottom: 5 }}>
              Welcome {currentUser?.firstName}!
            </Text>
          </GridRowColumn>
          <Text
            as="display2"
            style={{
              marginBottom: 5,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {cardState.headerText}
            <Pill color="orange" variant="solid">
              Due{' '}
              {isoToUTCDateTime(cardState.dueDate).toFormat(
                DATE_FORMATS_LUXON.MONTH_DAY
              )}
            </Pill>
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <Text as="bodySm" style={{ lineHeight: 1.5 }}>
              {cardState.description}
            </Text>
            {cardState.nextStepPill}
            <Button
              style={{ width: 'fit-content' }}
              onClick={cardState.button.onClick}
            >
              {cardState.button.text}
              <Icon icon={regular('arrow-right')} style={{ marginLeft: 8 }} />
            </Button>
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <Image
            src="https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg"
            style={{
              width: '170px',
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Grid.Column>
      </Grid>
    </Card>
  )
}

export default TaxSeasonKickoffEntryCard
