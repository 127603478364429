import { Button, Icon, Text } from '../../../components/BaseComponents'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import getNextStepStatusPill, { NextStepPill } from '../NextStepStatusPillRules'
import { useNavigate } from 'react-router-dom'
import { isNil } from 'lodash'
import { useReselector } from '../../../utils/sharedHooks'
import { selectMostUrgentModule } from '../yearEndModuleStatus.selectors'

const EOYNextStepPill = ({
  icon,
  title,
  subtext,
  backgroundColor,
  navigateTo,
}: NextStepPill) => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        display: 'inline-flex',
        width: 'fit-content',
        alignItems: 'center',
        borderRadius: '100px',
        backgroundColor,
        padding: '12px 24px',
        gap: 16,
      }}
    >
      <div>{icon}</div>
      <div>
        {title && (
          <Text as="bodySm" style={{ fontWeight: 500 }}>
            {title}
          </Text>
        )}
        <Text as="bodyXs">{subtext}</Text>
      </div>
      {!isNil(navigateTo) && (
        <div>
          <Button variant="secondaryLink" onClick={() => navigate(navigateTo)}>
            <Icon icon={solid('arrow-right')} />
          </Button>
        </div>
      )}
    </div>
  )
}

export default EOYNextStepPill

export const useYearEndNextStepPill = () => {
  const mostUrgentEndModuleStatus = useReselector(selectMostUrgentModule)
  const props = getNextStepStatusPill({
    moduleType: mostUrgentEndModuleStatus?.moduleType,
    status: mostUrgentEndModuleStatus?.status,
    substepIdentifier: mostUrgentEndModuleStatus?.substepIdentifier,
    dueDate: mostUrgentEndModuleStatus?.dueDate,
  })
  if (!props) {
    return null
  }
  return <EOYNextStepPill {...props} />
}
