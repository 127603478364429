import {
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  getDeadlineApproachingDaysUntilDueDate,
  UpcomingStatusTextWrapper,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../styles/theme'

const getDescription = (status: YearEndModuleStatusOptions) => {
  switch (status) {
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <UpcomingStatusTextWrapper
          availableAfterText={
            'We’ll connect you to a tax preparer after you submit your extension request survey.'
          }
        />
      )
    }
    default:
      return null
  }
}

const getAlertBody = (dueDate: string | null) => {
  const daysUntilDueDate = getDeadlineApproachingDaysUntilDueDate(dueDate)
  if (daysUntilDueDate === null) {
    return null
  }
  const link = (
    <Link
      href="https://support.joinheard.com/hc/en-us/articles/4421104098583-What-do-I-need-to-do-in-Heard-prior-to-filling-out-my-Tax-Checklist"
      newPage
      size="small"
      style={{
        textDecoration: 'underline',
        color: Colors.charcoal,
        textDecorationColor: Colors.charcoal,
      }}
    >
      More info
    </Link>
  )
  if (daysUntilDueDate <= 0) {
    return (
      <Text as="bodySm">
        After that, you may be at risk of penalties and interest. Please
        complete your tax return ASAP. {link}
      </Text>
    )
  }
  return (
    <Text as="bodySm">
      Your extension is now overdue. You may be at risk of penalties and
      interest. Please complete your tax return ASAP to avoid additional fines.{' '}
      {link}
    </Text>
  )
}

const FileExtensionRequestCard = ({
  status,
  dueDate,
  taxYear,
}: YearEndModuleStatusDynamicDataParams) => {
  const navigate = useNavigate()
  const description = getDescription(status)
  const onActionClick = () => {
    navigate('/taxes/annual/file_1099_nec/verify_business')
  }
  return (
    <YearEndModuleBaseCard
      key={YearEndModuleType.fileExtensionRequest}
      status={status}
      dueDate={dueDate}
      taxYear={taxYear}
      header="File Extension Request"
      imageUrl="https://heard-images.s3.amazonaws.com/assets/tax-extension.svg"
      description={<Text as="bodySm">{description}</Text>}
      timer={{
        unit: 'minutes',
        value: 25,
      }}
      deadlineApproaching={{
        ...defaultDeadlineApproaching,
        alertBody: getAlertBody(dueDate),
      }}
      button={{
        onActionClick,
      }}
    />
  )
}

export default FileExtensionRequestCard
