import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Divider, Icon } from 'semantic-ui-react'
import moment from 'moment'

import { useReselector, useFetchResponse } from '../../../utils/sharedHooks'
import { useAppDispatch } from '../../../utils/typeHelpers'
import { fetchFinancialAccountsIfNeeded } from '../../../actions/financialAccountActions'
import { fetchPlaidItemsIfNeeded } from '../../../actions/plaidItemActions'
import { selectDisconnectedFinancialAccounts } from '../../../selectors/financeSelectors'
import { selectCurrentAnnualTaxYear } from '../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { getMissingStatements } from '../../UserDocuments/userDocuments.slice'
import { getSCorpSetUpStatuses } from '../../Dashboard/SCorpUpsell/sCorpActions.selector'
import {
  selectUncategorizedTransactionsNotNeedClarification,
  selectUserTransactionsByCategoryIdentifierForYear,
} from '../../Transactions/transactions.selectors'
import { fetchFilteredUserTransactions } from '../../Transactions/transactions.slice'
import { fetchTransactionCategoriesIfNeeded } from '../../Reports/reports.slice'
import {
  TransactionCategoryIdentifier,
  Transaction,
} from '../../../reducers/admin/allTransactions.slice'

import { ProgressBarNoTitles } from '../../../components/BaseComponents/ProgressBar'
import Text from '../../../components/BaseComponents/Text'
import { NavButtons } from './nav-buttons'
import { IStep, STEPS } from './step-map'
import './styles.scss'
import {
  userGetBookkeepingStep,
  userUpdateBookkeepingStepContext,
} from './actions'

interface IUnclarifiedTransactions {
  uncatagorized: Transaction[]
  bookkeeperClarification: Transaction[]
}

export const EndOfYearReviewContainer = () => {
  const [currentStep, setCurrentStep] = useState<IStep | null>(null)
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const [userSteps, setUserSteps] = useState<IStep[]>([])
  const [prevStep, setPrevStep] = useState<string>('')
  const [nextStep, setNextStep] = useState<string>('')
  const [isStepComplete, setIsStepComplete] = useState<boolean>(true)
  const [preventMultipleCall, setPreventMultipleCall] = useState<boolean>(false)
  const [stepContext, setStepContext] = useState<Record<string, unknown>>({})
  const year = useReselector(selectCurrentAnnualTaxYear)
  const location = useLocation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const path = location.pathname.split('/')
    const stepRoute = path[4]
    const stepIndex = userSteps.findIndex((step) => step.route === stepRoute)
    if (stepIndex !== -1) {
      setCurrentStepIdx(stepIndex)
    }
  }, [location.pathname, userSteps])

  const setReviewIndex = (route: string) => {
    const stepIndex = userSteps.findIndex((step) => step.route === route)
    if (stepIndex !== -1) {
      setCurrentStepIdx(stepIndex)
    }
  }

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchFinancialAccountsIfNeeded())
      await dispatch(fetchPlaidItemsIfNeeded())
    }

    fetch()
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      fetchFilteredUserTransactions({
        startDate: moment().year(parseInt(year)).startOf('year'),
        endDate: moment().year(parseInt(year)).endOf('year'),
      })
    )
  }, [dispatch, year])

  const disconnectedBankAccounts = useReselector(
    selectDisconnectedFinancialAccounts
  )

  const unclarifiedTransactions = useReselector(
    selectUncategorizedTransactionsNotNeedClarification,
    year
  )

  // const uncatagorized = useReselector(
  //   selectUncategorizedTransactionsNotNeedClarification,
  //   year
  // )

  const otherExpenses = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_expenses,
    year
  )

  const otherIncome = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_income,
    year
  )

  const investmentTransactions = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_investments,
    year
  )

  const distributionTransactions = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_distribution,
    year
  )

  const missingStatements = useFetchResponse(getMissingStatements)

  const isSCorp = Object.keys(useReselector(getSCorpSetUpStatuses)).length !== 0

  const getUnclarifiedTransactions = (
    transactions: Transaction[]
  ): IUnclarifiedTransactions => {
    return transactions.reduce(
      (acc, transaction) => {
        // @ts-expect-error mocking out step state for now
        acc.uncatagorized.push(transaction)
        return acc
      },
      { bookkeeperClarification: [], uncatagorized: [] }
    )
  }

  const userBookkeepingStatus = useMemo(() => {
    return {
      disconnectedBankAccounts,
      missingBankStatements: missingStatements,
      unclarifiedTransactions: getUnclarifiedTransactions(
        unclarifiedTransactions
      ),
      otherExpenses,
      otherIncome,
      isSCorp,
      investmentTransactions,
      distributionTransactions,
      lastEoyBookkeepingScreen: '/base/year/step_slug',
    }
  }, [
    disconnectedBankAccounts,
    unclarifiedTransactions,
    otherExpenses,
    otherIncome,
    isSCorp,
    investmentTransactions,
    distributionTransactions,
    missingStatements,
  ])

  useEffect(() => {
    const steps = STEPS.filter((step) => {
      return step.isRequired(userBookkeepingStatus)
    })
    setUserSteps(steps)
  }, [userBookkeepingStatus])

  useEffect(() => {
    setCurrentStep(STEPS[currentStepIdx])
  }, [currentStepIdx])

  useEffect(() => {
    if (!preventMultipleCall)
      setIsStepComplete(userSteps[currentStepIdx]?.isAlwaysComplete || false)
    const prevStep =
      currentStepIdx === 0 ? 'foo' : userSteps[currentStepIdx - 1]?.route
    const nextStep =
      currentStepIdx === userSteps.length - 1
        ? 'foo'
        : userSteps[currentStepIdx + 1]?.route

    setPrevStep(prevStep)
    setNextStep(nextStep)
  }, [currentStepIdx, userSteps, preventMultipleCall])

  useEffect(() => {
    const fetchStep = async (stepId: string | undefined) => {
      if (stepId) {
        const step = await userGetBookkeepingStep(stepId)(dispatch)
        setStepContext(step?.context ?? {})
      }
    }
    fetchStep(currentStep?.id)
  }, [currentStep?.id, dispatch])

  const updateStepContext = useCallback(
    (factor: number) => {
      const fromStepId = userSteps[currentStepIdx]?.id
      const toStepId = userSteps[currentStepIdx + factor]?.id
      if (fromStepId && toStepId) {
        return userUpdateBookkeepingStepContext(
          fromStepId,
          toStepId,
          stepContext
        )(dispatch)
      }
      return true
    },
    [userSteps, currentStepIdx, stepContext, dispatch]
  )

  const handlePrevStep = useCallback(async () => {
    const success = await updateStepContext(-1)
    if (success) {
      setCurrentStepIdx((prev) => prev - 1)
      navigate(`/bookkeeping/end-of-year-review/${year}/${prevStep}`)
    }
  }, [updateStepContext, prevStep, year, navigate])

  const handleNextStep = useCallback(async () => {
    const success = await updateStepContext(1)
    if (success) {
      setCurrentStepIdx((prev) => prev + 1)
      navigate(`/bookkeeping/end-of-year-review/${year}/${nextStep}`)
    }
  }, [updateStepContext, nextStep, year, navigate])

  const handlSaveAndExit = useCallback(async () => {
    // PENDING desired nav path from Business
    const success = await updateStepContext(1)
    if (success) {
      navigate('/foo')
    }
  }, [updateStepContext, navigate])

  const setIsStepCompleteFun = (isComplete: boolean) => {
    setPreventMultipleCall(true)
    setIsStepComplete(isComplete)
  }

  const getCurrentStepComponent = () => {
    if (currentStepIdx === null || userSteps.length === 0) {
      return STEPS[0].component
    }
    return userSteps[currentStepIdx].component
  }

  const getCurrentProps = () => {
    if (userSteps?.[currentStepIdx] === null) {
      return {}
    }
    if (userSteps[currentStepIdx]?.route === 'review') {
      return {
        userSteps,
        setReviewIndex,
      }
    }

    if (userSteps?.[currentStepIdx]?.props !== null) {
      return {
        ...(userSteps[currentStepIdx]?.props(userBookkeepingStatus) || {}),
      }
    }

    return {}
  }

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon name="check" style={{ color: '#457634' }} />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>

      <ProgressBarNoTitles
        currentStep={currentStepIdx}
        totalSteps={userSteps.length}
      />

      <div className="step-body">
        {/* @ts-expect-error temp type issue resoltion - ticket hea-5043 */}
        {React.createElement(getCurrentStepComponent(), {
          ...getCurrentProps(),
          ...(currentStep?.isAlwaysComplete
            ? {}
            : { setIsStepComplete: setIsStepCompleteFun }),
          ...{ stepContext, setStepContext },
        })}
      </div>

      <Divider style={{ margin: 0 }} />

      <NavButtons
        isStepComplete={isStepComplete}
        stepBack={handlePrevStep}
        stepForward={handleNextStep}
        saveAndExit={handlSaveAndExit}
        isSubmit={userSteps[currentStepIdx]?.isSubmit}
      />
    </div>
  )
}
