import {
  upsertUserYearEndModuleStatus,
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import YearEndModuleBaseCard, {
  defaultDeadlineApproaching,
  YearEndModuleStatusDynamicDataParams,
} from './YearEndModuleStatusBaseCard'
import { Link, Text } from '../../../components/BaseComponents'
import FileUploadModal from '../../../components/FileUpload/FileUploadModal'
import { useState } from 'react'
import { Colors } from '../../../styles/theme'
import { useReselector } from '../../../utils/sharedHooks'
import { selectUserDocumentCategoryByInternalName } from '../../Admin/UserDocumentCategories/userDocumentCategories.selectors'
import { useAppDispatch } from '../../../utils/typeHelpers'

const getDescription = (status: YearEndModuleStatusOptions) => {
  switch (status) {
    case YearEndModuleStatusOptions.actionRequired: {
      return (
        <>
          Please upload your CP261 to verify your S corporation status.{' '}
          <Link
            href="https://support.joinheard.com/hc/en-us/articles/24263736881943-How-to-Request-S-corp-Verification-Letter-Form-385C"
            newPage
            size="small"
            style={{
              textDecoration: 'underline',
              color: Colors.charcoal,
              textDecorationColor: Colors.charcoal,
            }}
          >
            How to request your S corporation verification form
          </Link>
        </>
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return 'We can’t accurately finalize your 2024 books until you’ve confirmed your tax entity status. Please complete ASAP.'
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return 'We’re verifying your status. After we’re done, you’ll be able to continue with annual taxes.'
    }
    case YearEndModuleStatusOptions.complete: {
      return 'Your status has been verified.'
    }
    default:
      return null
  }
}

const VerifyTaxEntityCard = ({
  status,
  dueDate,
  taxYear,
}: YearEndModuleStatusDynamicDataParams) => {
  const dispatch = useAppDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const description = getDescription(status)
  const cp261DocumentCategory = useReselector(
    selectUserDocumentCategoryByInternalName,
    'cp261_notice'
  )
  const onUpload = () => {
    // may want to move this to backend
    upsertUserYearEndModuleStatus({
      moduleType: YearEndModuleType.verifyTaxEntity,
      status: YearEndModuleStatusOptions.waitingOnHeard,
    })(dispatch)
  }
  const buttonText =
    status === YearEndModuleStatusOptions.actionRequired
      ? 'Upload Now'
      : 'Start'
  return (
    <>
      <YearEndModuleBaseCard
        key={YearEndModuleType.verifyTaxEntity}
        taxYear={taxYear}
        status={status}
        dueDate={dueDate}
        header="Verify tax entity status"
        imageUrl="https://heard-images.s3.amazonaws.com/assets/statement-upload-manual.svg"
        description={<Text as="bodySm">{description}</Text>}
        timer={{
          unit: 'minutes',
          value: 15,
        }}
        deadlineApproaching={{
          ...defaultDeadlineApproaching,
          alertBody: (
            <Text as="bodySm">
              After that, you’ll need to complete an extension request so you’ll
              have more time to file your taxes.
            </Text>
          ),
        }}
        button={{
          text: buttonText,
          onActionClick: () => {
            setModalOpen(true)
          },
        }}
      />
      {cp261DocumentCategory?.type && (
        <FileUploadModal
          open={modalOpen}
          close={() => setModalOpen(false)}
          userFacing
          documentType={cp261DocumentCategory.type}
          categoryId={cp261DocumentCategory.id}
          setUploadedFile={() => {
            onUpload()
          }}
        />
      )}
    </>
  )
}

export default VerifyTaxEntityCard
