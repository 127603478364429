import { useEffect, useMemo } from 'react'
import { fetchUserYearEndModuleStatuses } from '../../../YearEndModuleStatus/yearEndModuleStatus.slice'
import { useReselector } from '../../../../utils/sharedHooks'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { selectCurrentAnnualTaxYear } from '../../../Admin/AnnualTaxDetails/annualTaxDetails.selector'
import {
  Accordion,
  Button,
  GridRowColumn,
  Link,
  Text,
} from '../../../../components/BaseComponents'
import { Grid, Image } from 'semantic-ui-react'
import {
  DeviceWidth,
  useIsDeviceWidth,
} from '../../../../utils/deviceWidthHelpers'
import { makeGridConfig } from '../../../../components/BaseComponents/Grid'
import YearEndModuleProgressList from '../../../YearEndModuleStatus/components/YearEndModuleProgressList'
import {
  getMostUrgentYearEndModuleType,
  selectOrderedCompletedYEModules,
  selectOrderedIncompleteYEModules,
} from '../../../YearEndModuleStatus/yearEndModuleStatus.selectors'
import { fetchUserDocumentCategoriesIfNeeded } from '../../../Admin/UserDocumentCategories/userDocumentCategories.slice'
import { useYearEndNextStepPill } from '../../../YearEndModuleStatus/components/EOYNextStepPill'

const Header = ({
  taxYear,
  optedOutOfTaxesWithHeard,
}: {
  taxYear: string
  optedOutOfTaxesWithHeard: boolean
}) => {
  const nextStepPill = useYearEndNextStepPill()

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const content = optedOutOfTaxesWithHeard
    ? {
        title: `${taxYear} Year-End Bookkeeping`,
        description:
          'We’ll walk you through getting your books in order for the year. This will set you up for success when you file your annual taxes.',
        image:
          'https://heard-images.s3.amazonaws.com/assets/books_lightShadow.svg',
      }
    : {
        title: 'Annual Taxes',
        description:
          'We’ll walk you through getting your books and documents in order for annual taxes. After we have everything we need, we’ll connect you to a tax preparer.',
        image: 'https://heard-images.s3.amazonaws.com/assets/annual_taxes.svg',
      }

  return (
    <GridRowColumn
      columnStyle={{
        display: 'flex',
        gap: 40,
      }}
    >
      <div>
        <Text as="h1" style={{ marginBottom: 8 }}>
          {content.title}
        </Text>
        {!isMobile && (
          <Text as="bodySm" style={{ marginBottom: 24 }}>
            {content.description}
          </Text>
        )}
        {nextStepPill}
      </div>
      {!isMobile && (
        <Image src={content.image} style={{ width: 180, height: 180 }} />
      )}
    </GridRowColumn>
  )
}

const FAQsSection = () => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
    <Text as="h2">FAQs</Text>
    <div>
      <Link
        href="https://support.joinheard.com/hc/en-us/articles/19788518004887-Taxfyle-FAQs"
        newPage
      >
        Why can’t I connect with a tax preparer yet?
      </Link>
    </div>
    <div>
      <Link
        as="secondaryLink"
        color="forest"
        href="https://support.joinheard.com/hc/en-us/articles/20989977806231-Annual-Tax-FAQs"
        newPage
      >
        View All FAQs
      </Link>
    </div>
  </div>
)

const OptionsSection = ({ taxYear }: { taxYear: string }) => {
  const optionsTitle = <Text as="h2">Options</Text>
  const titleStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  }
  const optionsContent = useMemo(() => {
    const subtextStyle = { paddingTop: 4 }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div>
          <Button variant="link" onClick={() => {}}>
            Opt out of annual taxes →
          </Button>
          <Text as="bodySm" style={subtextStyle}>
            If you no longer require tax services from Heard, start the opt out
            process here.
          </Text>
        </div>
        <div>
          <Button variant="link" onClick={() => {}}>
            Opt out of 1099-NEC filing →
          </Button>
          <Text as="bodySm" style={subtextStyle}>
            If you didn’t pay a contractor $600 or more in {taxYear}, you don’t
            need to file a 1099-NEC.
          </Text>
        </div>
      </div>
    )
  }, [taxYear])
  return (
    <Accordion
      title={optionsTitle}
      titleStyle={titleStyle}
      content={optionsContent}
      variant="text"
      iconColor="black"
      initialOpen
    />
  )
}

const RightSideContent = ({ taxYear }: { taxYear: string }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
      <FAQsSection />
      <OptionsSection taxYear={taxYear} />
    </div>
  )
}

const YearEndHome = () => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const completedYEModules = useReselector(selectOrderedCompletedYEModules)
  const incompleteModules = useReselector(selectOrderedIncompleteYEModules)
  const mostUrgentModuleType = useReselector(getMostUrgentYearEndModuleType)

  const isMobile = useIsDeviceWidth(DeviceWidth.mobile)
  const gridStyle = isMobile ? { padding: '0px 16px' } : {}
  useEffect(() => {
    dispatch(fetchUserYearEndModuleStatuses())
    dispatch(fetchUserDocumentCategoriesIfNeeded())
  }, [dispatch, taxYear])

  return (
    <Grid style={gridStyle}>
      <Header taxYear={taxYear} optedOutOfTaxesWithHeard={false} />
      <Grid.Row>
        <Grid.Column {...makeGridConfig([11, 16])}>
          <YearEndModuleProgressList
            completeModules={completedYEModules}
            incompleteModules={incompleteModules}
            taxYear={taxYear}
            mostUrgentModule={mostUrgentModuleType}
            annualTaxFormNeeds={null}
          />
        </Grid.Column>
        <Grid.Column {...makeGridConfig([5, 16])}>
          <RightSideContent taxYear={taxYear} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default YearEndHome
