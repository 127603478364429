import {
  YearEndModuleStatus,
  YearEndModuleStatusOptions,
  YearEndModuleType,
} from '../yearEndModuleStatus.slice'
import { Text, Accordion, Icon } from '../../../components/BaseComponents'
import { useMemo, useState } from 'react'
import VerifyTaxEntityCard from './VerifyTaxEntityCard'
import EoyBookkeepingCard from './EOYBookkeepingCard'
import EOYBookkeepingFollowupCard from './EOYBookkeepingFollowupCard'
import TaxQuestionnaireCard from './TaxQuestionnaireCard'
import SubmitExtensionRequestCard from './SubmitExtensionRequestCard'
import FileExtensionRequestCard from './FileExtensionRequestCard'
import File1040Card from './File1040Card'
import File1120SCard from './File1120SCard'
import File1099NECCard from './File1099NECCard'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import styled from 'styled-components'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Colors } from '../../../styles/theme'
import { TaxFormType } from '../../Taxes/AnnualTaxes/Questionnaires/constants'

export interface YearEndModuleProgressListProps {
  completeModules: YearEndModuleStatus[]
  incompleteModules: YearEndModuleStatus[]
  taxYear: string
  mostUrgentModule: YearEndModuleType | null
  annualTaxFormNeeds: Array<TaxFormType> | null
}

const getComponentForYearEndModule = ({
  yeModule,
  taxYear,
  isTwoFormFiler,
  annualTaxFormNeeds,
}: {
  yeModule: YearEndModuleStatus
  taxYear: string
  isTwoFormFiler: boolean
  annualTaxFormNeeds: Array<TaxFormType> | null
}) => {
  const props = {
    ...yeModule,
    taxYear,
  }
  switch (yeModule.moduleType) {
    case YearEndModuleType.verifyTaxEntity:
      return <VerifyTaxEntityCard {...props} />
    case YearEndModuleType.file1099Nec:
      return <File1099NECCard {...props} />
    case YearEndModuleType.eoyBookkeeping:
      return <EoyBookkeepingCard {...props} />
    case YearEndModuleType.eoyBookkeepingFollowup:
      return <EOYBookkeepingFollowupCard {...props} />
    case YearEndModuleType.taxQuestionnaire:
      return (
        <TaxQuestionnaireCard
          {...props}
          annualTaxFormNeeds={annualTaxFormNeeds}
        />
      )
    case YearEndModuleType.submitExtensionRequest:
      return <SubmitExtensionRequestCard {...props} />
    case YearEndModuleType.fileExtensionRequest:
      return <FileExtensionRequestCard {...props} />
    case YearEndModuleType.file1040:
      return <File1040Card {...props} isTwoFormFiler={isTwoFormFiler} />
    case YearEndModuleType.file1120S:
      return <File1120SCard {...props} />
    default:
      yeModule.moduleType satisfies never
      return null
  }
}

interface StatusCircleProps {
  backgroundColor?: string
  borderColor?: string
  icon?: IconDefinition
  isMostUrgent?: boolean
  zeroTopMargin?: boolean
}

const statusCircleWidth = 32
const StatusCircle = styled.div<StatusCircleProps>`
  width: ${statusCircleWidth}px;
  height: ${statusCircleWidth}px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor || Colors.white};
  ${(props) => props.borderColor && `border: 2px solid ${props.borderColor};`}
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  ${(props) =>
    props.isMostUrgent &&
    `&:after {
      content: " ";
      display: block;
      margin-left: 7rem;
      padding-right: 5rem;
      border-top: 2px solid ${props.borderColor || props.backgroundColor};
`}
`
const StatusLabel = (props: StatusCircleProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: props.zeroTopMargin ? 0 : 16,
      }}
    >
      <StatusCircle {...props}>
        {props.icon && <Icon color="white" icon={props.icon} />}
      </StatusCircle>
    </div>
  )
}

const getStatusColor = (status: YearEndModuleStatusOptions) => {
  switch (status) {
    case YearEndModuleStatusOptions.inProgress:
    case YearEndModuleStatusOptions.upNext:
      return Colors.orange
    case YearEndModuleStatusOptions.actionRequired:
      return Colors.yellow
    case YearEndModuleStatusOptions.waitingOnHeard:
      return Colors.blue
    case YearEndModuleStatusOptions.overdue:
      return Colors.red
    case YearEndModuleStatusOptions.upcoming:
      return Colors.mediumGray
    case YearEndModuleStatusOptions.complete:
      return Colors.accentGreen
    default:
      return Colors.mediumGray
  }
}

const getStatusLabel = (
  status: YearEndModuleStatusOptions,
  isMostUrgent: boolean
) => {
  switch (status) {
    case YearEndModuleStatusOptions.inProgress:
    case YearEndModuleStatusOptions.upNext: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.actionRequired: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.waitingOnHeard: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          icon={solid('hourglass')}
        />
      )
    }
    case YearEndModuleStatusOptions.overdue: {
      return (
        <StatusLabel
          borderColor={getStatusColor(status)}
          isMostUrgent={isMostUrgent}
        />
      )
    }
    case YearEndModuleStatusOptions.upcoming: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          icon={solid('lock')}
        />
      )
    }
    case YearEndModuleStatusOptions.complete: {
      return (
        <StatusLabel
          backgroundColor={getStatusColor(status)}
          icon={solid('check')}
        />
      )
    }
    default:
      return (
        <StatusLabel
          borderColor={Colors.mediumGray}
          isMostUrgent={isMostUrgent}
        />
      )
  }
}

const VerticalLineContainer = styled.div<{
  status: YearEndModuleStatusOptions
  last?: boolean
}>`
  background: ${(props) =>
    props.last
      ? 'none'
      : `linear-gradient(${Colors.gray}, ${Colors.gray}) no-repeat
    border-box left 50% top 16px /2px 100%;`};
  margin-bottom: -4rem;
  margin-right: 24px;
`
const YearEndItemContainer = styled.div`
  display: flex;
  padding: 1rem 0;
`

const YearEndListWithStatusIcons = ({
  yearEndModules,
  taxYear,
  mostUrgentModule,
  endLineOnLast = true,
  annualTaxFormNeeds,
}: {
  yearEndModules: YearEndModuleStatus[]
  taxYear: string
  mostUrgentModule?: YearEndModuleType | null
  endLineOnLast?: boolean
  annualTaxFormNeeds: Array<TaxFormType> | null
}) => {
  const isTwoFormFiler = Boolean(
    annualTaxFormNeeds && annualTaxFormNeeds.length > 1
  )
  const moduleCount = yearEndModules.length
  return (
    <div>
      {yearEndModules.map((yeModule, i) => {
        const component = getComponentForYearEndModule({
          yeModule,
          taxYear,
          isTwoFormFiler,
          annualTaxFormNeeds,
        })
        const isMostUrgent = mostUrgentModule === yeModule.moduleType
        return (
          <YearEndItemContainer key={yeModule.id}>
            <VerticalLineContainer
              status={yeModule.status}
              last={endLineOnLast && i === moduleCount - 1}
            >
              {getStatusLabel(yeModule.status, isMostUrgent)}
            </VerticalLineContainer>
            <div
              style={{
                flexGrow: 1,
              }}
            >
              {component}
            </div>
          </YearEndItemContainer>
        )
      })}
    </div>
  )
}

const CompletedModules = ({
  completeModules,
  taxYear,
  annualTaxFormNeeds,
}: {
  completeModules: YearEndModuleStatus[]
  taxYear: string
  annualTaxFormNeeds: Array<TaxFormType> | null
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false)
  const completedCount = completeModules.length
  const title = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 24,
          paddingBottom: accordionOpen ? 0 : '1rem',
        }}
      >
        {!accordionOpen && (
          <StatusLabel
            backgroundColor={getStatusColor(
              YearEndModuleStatusOptions.complete
            )}
            icon={solid('check')}
            zeroTopMargin
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 12,
            backgroundColor: Colors.stone40,
            borderRadius: 8,
            marginLeft: accordionOpen ? 55 : 24,
          }}
        >
          <Text as="h3" color="green">
            {accordionOpen
              ? 'Hide completed'
              : `Show ${completedCount} completed`}
          </Text>
          <Icon
            icon={
              accordionOpen ? regular('chevron-up') : regular('chevron-down')
            }
            style={{ marginLeft: 8 }}
            size="1x"
            color="green"
          />
        </div>
      </div>
    ),
    [accordionOpen, completedCount]
  )
  const content = useMemo(() => {
    return (
      <YearEndListWithStatusIcons
        yearEndModules={completeModules}
        taxYear={taxYear}
        endLineOnLast={false}
        annualTaxFormNeeds={annualTaxFormNeeds}
      />
    )
  }, [annualTaxFormNeeds, completeModules, taxYear])

  if (completeModules.length === 0) {
    return null
  }
  return (
    <Accordion
      variant="text"
      title={title}
      onOpenClick={setAccordionOpen}
      iconColor="transparent"
      content={content}
    />
  )
}

const YearEndModuleProgressList = ({
  completeModules,
  incompleteModules,
  taxYear,
  mostUrgentModule,
  annualTaxFormNeeds,
}: YearEndModuleProgressListProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Text as="h1">Your Progress</Text>

      <CompletedModules
        completeModules={completeModules}
        taxYear={taxYear}
        annualTaxFormNeeds={annualTaxFormNeeds}
      />
      <YearEndListWithStatusIcons
        yearEndModules={incompleteModules}
        taxYear={taxYear}
        mostUrgentModule={mostUrgentModule}
        annualTaxFormNeeds={annualTaxFormNeeds}
      />
    </div>
  )
}
export default YearEndModuleProgressList
