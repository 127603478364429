import {
  YearEndModuleType,
  YearEndModuleStatusOptions,
  EOYBookkeepingFollowupSubstep,
  FormFilingJobSubstep,
  YearEndModuleSubstepId,
} from './yearEndModuleStatus.slice'
import { Icon } from '../../components/BaseComponents'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Colors } from '../../styles/theme'
import { DATE_FORMATS_LUXON, formatISOFromUTC } from '../../utils/dateHelpers'
import { ReactNode } from 'react'
import { getModuleNameCopy } from './yearEndModuleStatus.helpers'

interface NextStepStatusPillProps {
  moduleType: YearEndModuleType | undefined
  status: YearEndModuleStatusOptions | undefined
  dueDate: string | null | undefined
  substepIdentifier: YearEndModuleSubstepId | null | undefined
}

export interface NextStepPill {
  icon: ReactNode
  title: string | null
  subtext: string
  backgroundColor: string
  navigateTo: string | null
}

const PILLS = {
  BK_ACTION_REQUIRED_FOLLOW_UP: {
    title: 'Action Required',
    subtext: 'Follow-up required to complete bookkeeping.',
    icon: <Icon icon={solid('seal-exclamation')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: '',
  },
  BK_ACTION_REQUIRED_REVIEW: {
    title: 'Action Required',
    subtext: 'Review and Sign off on your books.',
    icon: <Icon icon={solid('seal-exclamation')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: '',
  },
  RESPOND_TO_TAX_PREPARER: {
    title: 'Respond to tax preparer',
    subtext: 'Your return has been started.',
    icon: <Icon icon={solid('exclamation')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: '',
  },
  RESPOND_TO_TAX_PREPARER_QUESTIONS: {
    title: 'Respond to tax preparer',
    subtext: 'They have questions about your information.',
    icon: <Icon icon={solid('hourglass')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: '',
  },
  RESPOND_TO_TAX_PREPARER_DRAFT_READY: {
    title: 'Respond to tax preparer',
    subtext: 'Your tax return draft is ready for review.',
    icon: <Icon icon={solid('seal-exclamation')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: '',
  },
  RESPOND_TO_TAX_PREPARER_SIGN_AND_SUBMIT: {
    title: 'Respond to tax preparer',
    subtext: 'Sign and submit your tax return.',
    icon: <Icon icon={solid('comment-question')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: '',
  },
  NO_ACTION_NEEDED_AT_THIS_TIME: {
    title: '',
    subtext: 'No action needed at this time.',
    icon: <Icon icon={solid('hourglass')} />,
    backgroundColor: Colors.lightGray,
    navigateTo: null,
  },
  TAX_RETURN_DRAFT_IN_PROGRESS: {
    title: '',
    subtext: 'Your tax return draft is in progress.',
    icon: <Icon icon={solid('hourglass')} />,
    backgroundColor: Colors.lightGray,
    navigateTo: '',
  },
  PREPARE_TAX_FILING_FOR_SIGNATURE: {
    title: '',
    subtext: 'Preparing tax filing for your signature.',
    icon: <Icon icon={solid('hourglass')} />,
    backgroundColor: Colors.lightGray,
    navigateTo: '',
  },
  EXTENSION_REQUEST_IN_PROGRESS: {
    title: '',
    subtext: 'Your extension request is in progress.',
    icon: <Icon icon={solid('hourglass')} />,
    backgroundColor: Colors.lightGray,
    navigateTo: '',
  },
  START_TAX_SEASON_KICKOFF: {
    title: 'Start Tax Season Kickoff',
    subtext: 'Let us know your end of year tax needs',
    icon: <Icon icon={solid('sparkles')} />,
    backgroundColor: Colors.blush,
    navigateTo: '',
  },
  NO_ACTION_REQUIRED: {
    title: 'No action required',
    subtext: 'We`ll let you know if we need anything.',
    icon: <Icon icon={solid('hourglass')} />,
    backgroundColor: Colors.lightGray,
    navigateTo: null,
  },
  YOUR_NEXT_STEP: {
    title: 'Your next step',
    subtext: '',
    icon: <Icon icon={solid('sparkles')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: null,
  },
  CONTINUE_WORKING_ON: {
    title: 'Continue working on',
    subtext: '',
    icon: <Icon icon={solid('pen')} />,
    backgroundColor: Colors.sunrise,
    navigateTo: null,
  },
}

enum ModuleTypeCategory {
  file_1120_1040 = 'file_1120_1040',
  eoy_bk = 'eoy_bk',
  other = 'other',
  extension_request = 'extension_request',
  tax_questionnaire = 'tax_questionnaire',
}

enum EOYSubstepCategory {
  other = 'other',
}

// moduleType | status | substepIdentifier
const NEXT_STEP_RULES: Record<string, NextStepPill> = {
  // EOY Bookkeeping Follow-up rules
  [`${ModuleTypeCategory.eoy_bk}|${YearEndModuleStatusOptions.actionRequired}|${EOYSubstepCategory.other}`]:
    PILLS.BK_ACTION_REQUIRED_FOLLOW_UP,
  [`${ModuleTypeCategory.eoy_bk}|${YearEndModuleStatusOptions.waitingOnHeard}|${EOYSubstepCategory.other}`]:
    PILLS.BK_ACTION_REQUIRED_FOLLOW_UP,
  [`${ModuleTypeCategory.eoy_bk}|${YearEndModuleStatusOptions.actionRequired}|${EOYBookkeepingFollowupSubstep.userFollowup}`]:
    PILLS.BK_ACTION_REQUIRED_FOLLOW_UP,
  [`${ModuleTypeCategory.eoy_bk}|${YearEndModuleStatusOptions.actionRequired}|${EOYBookkeepingFollowupSubstep.finalUserApproval}`]:
    PILLS.BK_ACTION_REQUIRED_REVIEW,

  // File 1120s and 1040 rules
  [`${ModuleTypeCategory.file_1120_1040}|${YearEndModuleStatusOptions.waitingOnHeard}`]:
    PILLS.NO_ACTION_NEEDED_AT_THIS_TIME,
  [`${ModuleTypeCategory.file_1120_1040}|${YearEndModuleStatusOptions.actionRequired}|${FormFilingJobSubstep.startedUserCTA}`]:
    PILLS.RESPOND_TO_TAX_PREPARER,
  [`${ModuleTypeCategory.file_1120_1040}|${YearEndModuleStatusOptions.actionRequired}|${FormFilingJobSubstep.inProgressNeedsUserAction}`]:
    PILLS.RESPOND_TO_TAX_PREPARER_QUESTIONS,
  [`${ModuleTypeCategory.file_1120_1040}|${YearEndModuleStatusOptions.actionRequired}|${FormFilingJobSubstep.draftReadyForReview}`]:
    PILLS.RESPOND_TO_TAX_PREPARER_DRAFT_READY,
  [`${ModuleTypeCategory.file_1120_1040}|${YearEndModuleStatusOptions.actionRequired}|${FormFilingJobSubstep.signatureRequested}`]:
    PILLS.RESPOND_TO_TAX_PREPARER_SIGN_AND_SUBMIT,

  [`${ModuleTypeCategory.extension_request}|${YearEndModuleStatusOptions.inProgress}`]:
    PILLS.EXTENSION_REQUEST_IN_PROGRESS,

  [`${ModuleTypeCategory.tax_questionnaire}|${YearEndModuleStatusOptions.upNext}`]:
    PILLS.START_TAX_SEASON_KICKOFF,

  // if the module type is NOT: (eoy bk followup, file 1120s, file 1040, or file extension request)
  [`${ModuleTypeCategory.other}|${YearEndModuleStatusOptions.waitingOnHeard}`]:
    PILLS.NO_ACTION_REQUIRED,
  [`${ModuleTypeCategory.other}|${YearEndModuleStatusOptions.upNext}`]:
    PILLS.YOUR_NEXT_STEP,
  [`${ModuleTypeCategory.other}|${YearEndModuleStatusOptions.inProgress}`]:
    PILLS.CONTINUE_WORKING_ON,
}

const getNextStepStatusPill = ({
  moduleType,
  status,
  dueDate,
  substepIdentifier,
}: NextStepStatusPillProps): NextStepPill | null => {
  // figure out which category the module type falls under
  let moduleTypeCategory: ModuleTypeCategory
  if (
    moduleType === YearEndModuleType.file1120S ||
    moduleType === YearEndModuleType.file1040
  ) {
    moduleTypeCategory = ModuleTypeCategory.file_1120_1040
  } else if (moduleType === YearEndModuleType.eoyBookkeeping) {
    moduleTypeCategory = ModuleTypeCategory.eoy_bk
  } else if (
    moduleType === YearEndModuleType.fileExtensionRequest ||
    moduleType === YearEndModuleType.submitExtensionRequest
  ) {
    moduleTypeCategory = ModuleTypeCategory.extension_request
  } else if (moduleType === YearEndModuleType.taxQuestionnaire) {
    moduleTypeCategory = ModuleTypeCategory.tax_questionnaire
  } else {
    moduleTypeCategory = ModuleTypeCategory.other
  }

  // construct rule key
  let ruleKey = `${moduleTypeCategory}|${status}`

  if (substepIdentifier) {
    ruleKey += `|${substepIdentifier}`
  }

  if (
    moduleTypeCategory === ModuleTypeCategory.eoy_bk &&
    substepIdentifier !== EOYBookkeepingFollowupSubstep.userFollowup &&
    substepIdentifier !== EOYBookkeepingFollowupSubstep.finalUserApproval
  ) {
    ruleKey += `|${EOYSubstepCategory.other}`
  }

  const statusPill = NEXT_STEP_RULES[ruleKey]
  const dueDateToDisplay =
    dueDate && status !== YearEndModuleStatusOptions.waitingOnHeard
      ? dueDate
      : null
  if (statusPill) {
    return {
      ...statusPill,
      subtext:
        statusPill.subtext.length < 1 && moduleType
          ? getModuleNameCopy(moduleType)
          : dueDateToDisplay
            ? `${statusPill.subtext} (Due ${formatISOFromUTC(
                dueDate,
                DATE_FORMATS_LUXON.DISPLAY_LONG
              )})`
            : statusPill.subtext,
    }
  }

  return PILLS.CONTINUE_WORKING_ON
}

export default getNextStepStatusPill
